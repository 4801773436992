import styled from "styled-components";

export const Links = styled.div`
    position: relative;
    font-family: Amelie;
    font-size: 22pt;
    line-height: 0;
    margin: ${props => props.left ? '0 0.3vw 0.3vw 0' : '0 0 0.3vw 0.3vw'};

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 18pt;
        margin: ${props => props.left ? '0 1vw 1.2vw 0' : '0 0 1vw 1.2vw'};
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        margin: ${props => props.left ? '0 0.5vw 1vw 0' : '0 0 1vw 0.5vw'};
    }
`;

export const RoundIcon = styled.div`
    width: ${props => props.width || '2vw'};
    height: ${props => props.height || '2vw'};
    border-radius: 50%;
    background-color: ${props => props.circleFill || '#dbb28c'};
    position: relative;
    z-index: 3;

    display: flex;
    justify-content: center;

    :hover {
        background-color: ${props => props.noHover ? 'default' : 'var(--lava)'};
        cursor: pointer;
    }

    

    @media screen and (max-width: 1023px){
        width: 3vw;
        height: 3vw;
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        width: 7vw;
        height: 7vw;
    }
`;