import styled from "styled-components";

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1vw;
    padding: 5vw 0;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 5vw 5vw;
        // width: 80%;
    }
`;

export const Box = styled.div`
    background-color: var(--warmwhite);
    height: min-content;
    padding: 3.5vw;
`;