import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    fill: ${props => props.color || 'black'};
    size: ${props => props.vinkjeWidth || '10px'};
    margin: ${props => props.margin || '0'};
`;

export default function VinkjeIcon(props) {
    return (
        <SVG id="Vinkje" data-name="Vinkje" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.82 38.3" {...props}>
            <path id="Path_347" data-name="Path 347" d="M1.34,23.63a7.86,7.86,0,0,1,1.32.6A18,18,0,0,1,4.32,25.6c1.9,1.68,3.78,3.38,5.69,5,.69.6.58.65,1.38.07,1.44-1.06,11.79-12.17,12.87-13.6C26.75,13.81,37.86,1.33,39.13.56A4.45,4.45,0,0,1,40.75,0c.86-.11,1.31.52,1,1.31a9.47,9.47,0,0,1-.9,1.58c-.47.68-1,1.33-1.52,2-2.15,2.51-24,29.53-26.18,32A5.24,5.24,0,0,1,11.8,38a2.35,2.35,0,0,1-3.09-.74Q5.32,32.85,2,28.35C1.43,27.6,1,26.78.46,26a4.14,4.14,0,0,1-.31-.59C-.27,24.37.19,23.71,1.34,23.63Z"/>
        </SVG>
    )
}