import styled from "styled-components";

export const H1 = styled.div`
    font-family: Amelie;
    color: var(--stone);
    font-size: 2.5em;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 1.5em;
    }
`;

export const H2 = styled.div`
    font-family: Amelie;
    color: var(--lava);
    font-size: 1.5em;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 1em;
    }
`;

export const Paragraph = styled.div`
    font-family: Gayathri;
`;