import React from 'react';
import { StyledTextField, StyledTextArea } from './styled';

const primaryStyle = {
    name: 'primary',
    border: '#EFDECF',
    color: '#9B4625',
    placeholder: '#9B4625',
    focusBorder: '#92330f'
}

const secondaryStyle = {
    name: 'secondary',
    border: '#CADED4',
    color: '#145f68',
    placeholder: '#145f68',
    focusBorder: '#145f68'
}

const defaultStyle = {
    name: 'default',
    border: 'white',
    color: '#032d34',
    placeholder: '#032d34',
    focusBorder: '#032d34'
}

const customStyles = [ defaultStyle, primaryStyle, secondaryStyle ];

export default function TextField(props) {
    let maxLength = 200;
    if (props.ibclc) {
        maxLength = 8;
    } else if (props.maxLength) {
        maxLength = props.maxLength;
    }

    let customStyle = defaultStyle;
    if (props.customStyle) {
        customStyle = customStyles.filter(v => v.name === props.customStyle)[0];
    }

    if (props.textArea) {
        return (
            <div>
                <StyledTextArea
                    disabled={props.disabled}
                    value={props.value} 
                    placeholder={props.required && props.star ? props.placeholder.concat("*") : props.placeholder}
                    onChange={props.onChange}
                    style={props.style}
                    required={props.required}
                    type={props.type ? props.type : 'text'}
                    id={props.id}
                    maxLength={maxLength}
                    customStyle={customStyle}
                    textAlign={props.textAlign}
                />
                {props.errors && <div style={{ color: '#9B4625', margin: '0 0 0.5vw 0', textAlign: 'left' }}>{props.errors}</div>}
            </div>
        )
    }

    return (
        <div>
            <StyledTextField
                disabled={props.disabled}
                value={props.value} 
                placeholder={props.required && props.star ? props.placeholder.concat("*") : props.placeholder}
                onChange={props.onChange}
                style={props.style}
                required={props.required}
                type={props.type ? props.type : 'text'}
                id={props.id}
                maxLength={maxLength}
                customStyle={customStyle}
                textAlign={props.textAlign}
                width={props.width}
            />
            {props.errors && <div style={{ color: '#9B4625', margin: '0 0 0.5vw 0', textAlign: 'left' }}>{props.errors}</div>}
        </div>
    )
}