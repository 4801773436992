import styled from 'styled-components';

export const StyledTextArea = styled.textarea`
    font-family: Gayathri;
    background: transparent;
    color: ${props => props.disabled ? props.customStyle.color : 'black'};
    font-size: 14px;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};

    border: none;
    border-bottom: 3px solid ${props => props.customStyle.border};

    line-height: 1.1vw;
    margin: 0.5vw 0;
    width: 20vw;

    opacity: ${props => props.disabled ? '0.5' : '1'};

    ::placeholder {
        color: ${props => props.customStyle.placeholder};
    }

    &:focus {
        outline: none;
        border-color: ${props => props.customStyle.focusBorder};
        border-width: 3px;
        color: var(--bravery);
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: ${props => props.disabled ? '0' : 'default'};
        font-size: 1.1em;
        // padding: 7vw 0;
        width: 50vw;
        line-height: normal;
    }
`;

export const StyledTextField = styled.input`
    font-family: Gayathri;
    background: transparent;
    color: ${props => props.disabled ? props.customStyle.color : 'black'};
    font-size: 16px;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};

    border: none;
    border-bottom: 2px solid ${props => props.customStyle.border};

    line-height: 1.1vw;
    margin: 0.5vw 0;
    width: ${props => props.width || '100%'};

    opacity: ${props => props.disabled ? '0.5' : '1'};

    ::placeholder {
        color: ${props => props.customStyle.placeholder};
    }

    &:focus {
        outline: none;
        border-color: ${props => props.customStyle.focusBorder};
        border-width: 2px;
        color: var(--bravery);
    }

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: ${props => props.disabled ? '0' : 'default'};
        font-size: 14px;
        margin: 2vw 0;
        width: ${props => props.width || '100%'};
    }

    /* #### Tablets Portrait or Landscape #### */
    @media screen and (min-width: 767px) and (max-width: 1023px) {
        padding: ${props => props.disabled ? '0' : 'default'};
        width: ${props => props.width || '100%'};
    }
`;