import React from "react";
import { H1, H2, Paragraph } from "./styled";

export default function Typography(props) {
    if (props.type === 'h1') {
        return (
            <H1 style={props.style}>{props.children}</H1>
        )
    } else if (props.type === 'h2') {
        return (
            <H2 style={props.style}>{props.children}</H2>
        )
    }

    // paragraph
    return <Paragraph style={props.style}>{props.children}</Paragraph>
}