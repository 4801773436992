import React from 'react';
import { Button, Typography } from '../../components';
import { Circle, Main } from './styled';

export default function NotFound(props) {
    return (
        <Main>
            <Circle>
                <Typography type='h1'>Oeps! <br/> Deze pagina lijkt <br/> niet te bestaan</Typography>
            </Circle>
            <Button 
                to='/'
                text='Ga terug naar e-learning'
            />
            <Button 
                href='https://www.goedaangelegd.nl'
                text='Ga terug naar homepage'
            />
        </Main>
    );
}