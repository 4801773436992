import React from "react";

import { Content, LogoBar, Logo, ButtonBar } from './styled';

import logo from '../../assets/logo.png';

export default function Menu(props) {
    return (
        <Content>
            <LogoBar to='/'>
                    <Logo src={logo} />
            </LogoBar>
            <ButtonBar>
                {/* <Button 
                    to='/account'
                    buttonStyle='thirdly'
                    text='account'
                />
                <Button 
                    to='/uitloggen'
                    buttonStyle='thirdly'
                    text='uitloggen'
                /> */}
            </ButtonBar>
        </Content>
    )
}