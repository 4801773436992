import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    width: ${props => props.width || '2vw'};
    margin: ${props => props.margin || '0'};
`;

const Circle = styled.circle`
    fill: ${props => props.circleFill || '#fff'};

    &:hover {
        fill: ${props => props.hoverCircleFill || 'fff'}
        cursor: pointer;
    }
`;

const Path = styled.path`
    fill: ${props => props.fill ? props.fill : '#dbb28c'};
`;

export default function LuidsprekerIcon(props) {
    return (
        <SVG id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.1 35.1" {...props}>
            <Circle id="Ellipse_23" data-name="Ellipse 23" cx="17.55" cy="17.55" r="17.55" {...props} />
            <g id="Group_297" data-name="Group 297">
                <Path id="Path_363" data-name="Path 363" d="M6.49,18.68c0-.92.08-2.21.13-3.13,0-.65.16-.77.81-.78h3.43a1.36,1.36,0,0,0,.83-.26c1.64-1.09,3.29-1.81,5-2.86a3.55,3.55,0,0,1,1.23-.44c.73-.14,1.1.13,1.13.86,0,.91,0,1.81,0,2.71,0,1.51-.08,3-.1,4.52,0,1.65,0,3.3,0,4.94a3.34,3.34,0,0,1-.12.77.94.94,0,0,1-1.13.68.83.83,0,0,1-.3-.14c-.9-.65-1.84-1.22-2.75-1.85s-1.74-.86-2.6-1.5a1.74,1.74,0,0,0-1.17-.34c-1.08,0-2.17,0-3.25,0s-1.1-.11-1.08-1.11c0-.49,0-1.33,0-1.82,0-.1,0-.2,0-.3Zm5.69-2.47c0,.93.07,2.62,0,3.58a.89.89,0,0,0,.52.95,26,26,0,0,1,4.35,2.56,1.36,1.36,0,0,0,.3.13c0-1.74-.08-3.4-.09-5.07s.16-3.35,0-5.1c-1.73,1.12-3.38,1.82-5.14,3Zm-3.94.22c0,.56,0,1.41,0,1.89s0,1.35,0,1.82a11.23,11.23,0,0,0,3,0c0-1,0-2.71,0-3.73Z" {...props}/>
                <Path id="Path_364" data-name="Path 364" d="M29.1,18.57a12.59,12.59,0,0,1-3.47,8.57c-.34.37-.72.71-1.09,1a.79.79,0,0,1-.4.24.62.62,0,0,1-.47-.15.53.53,0,0,1,0-.49,2.2,2.2,0,0,1,.48-.53,12,12,0,0,0,3.77-6.91,10.18,10.18,0,0,0-3-9.29c-.18-.18-.38-.36-.55-.55a.5.5,0,0,1-.09-.66c.13-.2.42-.23.7-.05a4.88,4.88,0,0,1,.66.52A11.51,11.51,0,0,1,29.1,18.57Z" {...props}/>
                <Path id="Path_365" data-name="Path 365" d="M25.87,18.86a7.54,7.54,0,0,1-2.56,5.67,3.88,3.88,0,0,1-.58.42.48.48,0,0,1-.65-.08.51.51,0,0,1,.08-.71,2.09,2.09,0,0,1,.22-.2,6.93,6.93,0,0,0,.77-9.77L23,14a4.78,4.78,0,0,1-.6-.67.67.67,0,0,1-.07-.55.37.37,0,0,1,.49-.21l.07,0a2.79,2.79,0,0,1,.63.45A8.19,8.19,0,0,1,25.87,18.86Z" {...props}/>
                <Path id="Path_366" data-name="Path 366" d="M23.26,19.11a3.57,3.57,0,0,1-1.44,2.69,2.26,2.26,0,0,1-.52.3.48.48,0,0,1-.62-.27.46.46,0,0,1,.11-.51,1.61,1.61,0,0,1,.28-.24A2.91,2.91,0,0,0,21.63,17c-.05-.07-.11-.13-.17-.2a8.21,8.21,0,0,0-.6-.58c-.3-.3-.37-.57-.18-.79s.47-.22.82,0A4.38,4.38,0,0,1,23.26,19.11Z" {...props}/>
            </g>
        </SVG>
    )
}