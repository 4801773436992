import styled from "styled-components";

export const Main = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const Box = styled.div`
    background-color: var(--warmwhite);
    height: min-content;
    padding: 3.5vw;
`;