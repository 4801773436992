import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
    fill: ${props => props.color || 'black'};
    width: ${props => props.width || '1vw'};
    margin: ${props => props.margin || '0'};
`;

export default function LoginIcon(props) {
    return (
        <SVG id="Login" data-name="Login" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.11 136.51" {...props}>
            <path d="M47.57,66.21a33.11,33.11,0,1,0-33.11-33.1A33.1,33.1,0,0,0,47.57,66.21Zm0-61.51C60.4,4.7,70.8,18,70.8,33.11s-10.4,28.4-23.23,28.4S24.33,48.24,24.33,33.11,34.73,4.7,47.57,4.7Z"/><path d="M149.59,79.73H138.12V58.08a20.57,20.57,0,0,0-41.13,0V79.73H90.11A24,24,0,0,0,70.55,69.32H65.4a13.1,13.1,0,0,0-4.51.79,39.59,39.59,0,0,1-26.65,0,13.06,13.06,0,0,0-4.51-.79H24.59C11,69.32,0,81.16,0,95.76v24.86a3.82,3.82,0,0,0,3.68,3.95h78V133a3.53,3.53,0,0,0,3.53,3.53h64.34a3.53,3.53,0,0,0,3.52-3.53V83.25A3.53,3.53,0,0,0,149.59,79.73ZM104.68,58.45c0-8.47,5.21-16.34,12.87-16.34S130.42,50,130.42,58.45V79.73H104.68ZM10.61,119.57A2.61,2.61,0,0,1,8,117V95.76c0-11.82,5.79-21.44,16.59-21.44h5.14a8.75,8.75,0,0,1,2.95.54,44.58,44.58,0,0,0,29.78,0,8.69,8.69,0,0,1,2.94-.54h5.15a14.68,14.68,0,0,1,12.36,6.3,3.49,3.49,0,0,0-1.19,2.63v36.32ZM145,127.4a2.8,2.8,0,0,1-2.73,2.87H92.54a2.8,2.8,0,0,1-2.72-2.87V88.84A2.81,2.81,0,0,1,92.54,86h49.75A2.81,2.81,0,0,1,145,88.84Z"/>
            <path d="M123.34,109.23a9.23,9.23,0,0,0-5-16.25h-.09v0h-1.59v0h-.08a9.23,9.23,0,0,0-5,16.28l-2.88,10.44a2,2,0,0,0,1.91,2.51h13.73a2,2,0,0,0,1.91-2.51Zm-5.92-12.61a2.75,2.75,0,0,1,.4,0c2.23.26,4,2.69,4,5.52a6.13,6.13,0,0,1-1.84,4.52,4.2,4.2,0,0,1-.49.37,1.33,1.33,0,0,0-.64,1.55l2.8,10.16h-8.41l2.8-10.14a1.35,1.35,0,0,0-.63-1.57,3.5,3.5,0,0,1-.49-.37,6.1,6.1,0,0,1-1.85-4.52c0-2.83,1.74-5.26,4-5.52a2.55,2.55,0,0,1,.39,0Z"/>
            <path d="M117.42,95.82Z"/>
            <path d="M117.42,95.82h0Z"/>
        </SVG>
    )
}