import React, { useState } from 'react';

import { Main } from './styled';
import { Typography, TextField, Button } from '../../components';
import { GeneralContext } from "../../App";

import workshop1 from '../../assets/workshops/workshop2.png';
import workshop2 from '../../assets/workshops/workshop3.png';
import workshop3 from '../../assets/workshops/workshop4.png';

const workshops = [
    {
        'name': 'E-learning CENTERING FEEDING 1 (tijdens de zwangerschap)',
        'code': process.env.REACT_APP_CODE_WORKSHOP_ONE,
        'link': process.env.REACT_APP_LINK_WORKSHOP_ONE,
        'image': workshop1,
    },
    {
        'name': 'E-learning CENTERING FEEDING 2 (tijdens de zwangerschap)',
        'code': process.env.REACT_APP_CODE_WORKSHOP_TWO,
        'link': process.env.REACT_APP_LINK_WORKSHOP_TWO,
        'image': workshop2,
    },
    {
        'name': 'E-learning CENTERING FEEDING 3 (tijdens de kraamperiode)',
        'code': process.env.REACT_APP_CODE_WORKSHOP_THREE,
        'link': process.env.REACT_APP_LINK_WORKSHOP_THREE,
        'image': workshop3,
    }
]

export default function Home(props) {
    const { hasPhone } = React.useContext(GeneralContext);

    const [ codeOne, setCodeOne ] = useState('');
    const [ codeTwo, setCodeTwo ] = useState('');
    const [ codeThree, setCodeThree ] = useState('');
    const [ error, setErrorMessage ] = useState('');

    const getFieldCode = (id) => {
        if (id === 0) {
            return codeOne;
        } else if (id === 1) {
            return codeTwo;
        } else if (id === 2) {
            return codeThree;
        }
        return null;
    }

    const setFieldCode = (code, id) => {
        if (id === 0) {
            setCodeOne(code);
        } else if (id === 1) {
            setCodeTwo(code);
        } else if (id === 2) {
            setCodeThree(code)
        }
    }

    const forwardWorkshop = (id) => {
        let currCode = '';
        let codeFunc = null;
        if (id === 0) {
            currCode = codeOne;
            codeFunc = setCodeOne;
        } else if (id === 1) {
            currCode = codeTwo;
            codeFunc = setCodeTwo;
        } else if (id === 2) {
            currCode = codeThree;
            codeFunc = setCodeThree;
        } else {
            setErrorMessage('Er is iets verkeerd gegaan, probeer het opnieuw!')
            return;
        }

        setErrorMessage('');
        const currWorkshop = workshops[id];

        if (currCode !== currWorkshop.code) {
            setErrorMessage('Je hebt een verkeerde code ingevoerd, probeer het opnieuw!')
        } else {
            // forward to the link
            codeFunc('');
            setErrorMessage('');
            window.open(currWorkshop.link, '_blank');
        }
        
    }

    return (
        <Main>
            <Typography type='h1'>E-learning Goed Aangelegd</Typography>
            <Typography style={{ margin: hasPhone ? '2vw 0 5vw 0' : '0 0 2vw 0' }}>Vul jouw persoonlijke code uit het cursusboek in om door te gaan.</Typography>
            {error.length > 0 && <Typography style={{ color: 'red'}}>{error}</Typography>}
            {workshops.map((w, i) => (
                <div key={i} style={{ display: 'flex', alignItems: hasPhone ? 'flex-start' : 'center', gap: '1vw', margin: hasPhone ? '5vw 0' : '1vw 0', maxHeight: 'min-content' }}>
                    <img 
                        alt={w.name}
                        src={w.image} 
                        style={{
                            'maxHeight': hasPhone ? '5em' : '8em'
                        }}
                    />
                    <div>
                        <Typography type='h2'>{w.name}</Typography>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5vw', margin: '1vw 0', flexWrap: 'wrap' }}>
                            
                            <TextField 
                                value={getFieldCode(i)}
                                onChange={(event) => setFieldCode(event.target.value, i)}
                                placeholder='Workshop code'
                                required={true}
                                customStyle='secondary'
                                width={!hasPhone ? '15em' : 'default'}
                            />
                            <Button 
                                buttonstyle='secondary'
                                text='Ga door →'
                                onClick={() => forwardWorkshop(i)}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </Main>
    )
}