import React from 'react';

import IngelogdIcon from './IngelogdIcon';
import LoginIcon from './LoginIcon';
import Luidspreker2Icon from './Luidspreker2Icon';
import LuidsprekerIcon from './LuidsprekerIcon';
import VinkjeIcon from './VinkjeIcon';
import WinkelwagenIcon from './WinkelwagenIcon';

import { AiOutlineInstagram } from 'react-icons/ai';

import { Links } from './styled';

export default function Icon(props) {
    switch(props.name) {
        case 'ingelogd':
            return <IngelogdIcon {...props} />
        case 'login':
            return <LoginIcon {...props} />
        case 'luidspreker2':
            return <Luidspreker2Icon {...props} />;
        case 'luidspreker':
            return <LuidsprekerIcon {...props} />;
        case 'vinkje':
            return <VinkjeIcon {...props} />
        case 'winkelwagen':
            return <WinkelwagenIcon {...props} />
        case 'links':
            return <Links left={true} {...props}>{`<`}</Links>
        case 'rechts':
            return <Links {...props} >{`>`}</Links>
        default:
            return null;
    }
}