import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    justify-content: center;
    position: relative;

    background-color: var(--believe);
    
    height: 3em;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        font-size: 1em;
        line-height: 1em;
    }
`;

export const LogoBar = styled(Link)`
    display: flex;
    width: min-content;
    justify-content: center;
    align-items: center;
`;

export const Logo = styled.img`
    height: 60%;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        height: 60%;
    }
`;

export const ButtonBar = styled.div`
    display: flex;
    gap: 1vw;

    position: absolute;
    z-index: 1;
    right: 1vw;
    top: 1vw;
`;