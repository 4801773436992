import styled from "styled-components";

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;

    width: 100%;
`; 

export const Circle = styled.div`
    background-color: var(--warmwhite);
    border-radius: 50%;
    width: 20vw;
    height: 20vw;

    margin-bottom: 2vw;

    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
`;