import React from 'react';

export default function Luidspreker2Icon(props) {
    return (
        <svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 84">
            {/* <defs><style>'.cls-1{fill:#dbb28c;}.cls-2{fill:#be6942;}'</style></defs> */}
            <circle id="Ellipse_23" data-name="Ellipse 23" class="cls-1" cx="42" cy="42" r="42"/>
            <g id="Group_297" data-name="Group 297">
                <path id="Path_363" data-name="Path 363" class="cls-2" d="M15.33,44.14c.1-2.18.19-5.22.32-7.4.09-1.54.38-1.82,1.9-1.83,2.7,0,5.41,0,8.12,0a3.27,3.27,0,0,0,1.95-.63c3.87-2.58,7.77-4.27,11.71-6.74a8.61,8.61,0,0,1,2.91-1.05c1.72-.33,2.59.31,2.67,2,.1,2.13,0,4.27,0,6.4-.06,3.56-.2,7.12-.24,10.68-.05,3.89,0,7.78-.06,11.68a7.12,7.12,0,0,1-.29,1.82,2.21,2.21,0,0,1-2.67,1.6,2,2,0,0,1-.72-.33c-2.12-1.53-4.35-2.9-6.49-4.38s-4.13-2-6.14-3.54a4.06,4.06,0,0,0-2.77-.81c-2.57.09-5.13.1-7.7.08-2.38,0-2.59-.27-2.56-2.63,0-1.14,0-3.14.05-4.28,0-.24,0-.48,0-.71ZM28.77,38.3c.05,2.18.16,6.17.1,8.44A2.11,2.11,0,0,0,30.1,49c3.63,2,7.07,3.4,10.26,6a3.4,3.4,0,0,0,.71.31c-.07-4.1-.17-8-.21-12s.37-7.91,0-12C36.83,34,32.94,35.62,28.77,38.3Zm-9.3.51c0,1.33.05,3.32.07,4.46s.05,3.18.07,4.31a26.12,26.12,0,0,0,7.12-.08c0-2.33.07-6.4.1-8.83Z"/>
                <path id="Path_364" data-name="Path 364" class="cls-2" d="M68.73,43.87a29.68,29.68,0,0,1-8.19,20.25c-.79.87-1.69,1.66-2.56,2.46a1.92,1.92,0,0,1-1,.56,1.49,1.49,0,0,1-1.11-.35,1.29,1.29,0,0,1-.08-1.16A5.65,5.65,0,0,1,57,64.39a28.48,28.48,0,0,0,8.9-16.33,24.07,24.07,0,0,0-7.18-22c-.44-.43-.9-.84-1.31-1.3a1.17,1.17,0,0,1-.21-1.56c.32-.47,1-.54,1.67-.1a10.8,10.8,0,0,1,1.55,1.24C65.51,29.15,68.45,35.81,68.73,43.87Z"/>
                <path id="Path_365" data-name="Path 365" class="cls-2" d="M61.1,44.56a17.76,17.76,0,0,1-6,13.38,9.4,9.4,0,0,1-1.37,1,1.12,1.12,0,0,1-1.55-.21,1.19,1.19,0,0,1,.19-1.66,3.89,3.89,0,0,1,.53-.46C60,50.82,60.43,39.82,54.31,33.1a13.43,13.43,0,0,1-1.42-1.59,1.68,1.68,0,0,1-.16-1.3.89.89,0,0,1,1.16-.49.51.51,0,0,1,.16.09,6.87,6.87,0,0,1,1.49,1.07A19.23,19.23,0,0,1,61.1,44.56Z"/>
                <path id="Path_366" data-name="Path 366" class="cls-2" d="M54.93,45.15a8.42,8.42,0,0,1-3.4,6.35,4.67,4.67,0,0,1-1.23.7,1.12,1.12,0,0,1-1.2-1.85,4.21,4.21,0,0,1,.65-.55,6.87,6.87,0,0,0,1.34-9.62c-.13-.16-.26-.32-.4-.47-.43-.5-.95-.92-1.42-1.38-.72-.7-.86-1.34-.44-1.87s1.13-.52,2,0C53.12,37.82,54.91,41.21,54.93,45.15Z"/>
            </g>
        </svg>
    )
}