import styled from 'styled-components';

export const Bar = styled.div`
    background-color: var(--warmwhite);
    padding: 0.5vw 0 0.3vw 0;

    font-size: 1em;
    color: var(--stone);
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* #### Mobile Phones Portrait or Landscape #### */
    @media screen and (max-width: 766px){
        padding: 1em 0;
        font-size: 0.8em;
    }
`;

export const Subbar = styled(Bar)`
    gap: 2vw;
`;