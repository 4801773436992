const primary = {
    name: 'primary',
    textColor: 'white',
    boxColor: '#dbb28c',
    hoverTextColor: 'white',
    hoverBoxColor: 'var(--expectation)'
}

const secondary = {
    name: 'secondary',
    textColor: 'white',
    boxColor: 'var(--expectation)',
    hoverTextColor: 'white',
    hoverBoxColor: 'var(--trust)'
}

const thirdly = {
    name: 'thirdly',
    textColor: 'var(--trust)',
    boxColor: 'white',
    hoverTextColor: 'white',
    hoverBoxColor: 'var(--trust)'
}

const fourthly = {
    name: 'fourthly',
    textColor: 'white',
    boxColor: '#dbb28c',
    hoverTextColor: 'white',
    hoverBoxColor: 'var(--lava)'
}

const fifthly = {
    name: 'fifthly',
    textColor: '#145f68',
    boxColor: '#f6efe7',
    hoverTextColor: '#114d55',
    hoverBoxColor: '#c1d8cb'
}

const sixthly = {
    name: 'sixthly',
    textColor: 'white',
    boxColor: 'var(--expectation)',
    hoverTextColor: 'white',
    hoverBoxColor: '#dbb28c'
}

const seventhly = {
    name: 'seventhly',
    textColor: '#BE7B54',
    boxColor: '#f6efe7',
    hoverTextColor: '#f6efe7',
    hoverBoxColor: 'var(--lava)'
}

export const buttonStyles = [primary, secondary, thirdly, fourthly, fifthly, sixthly, seventhly]
