import styled from 'styled-components';

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    height: 100vh;
    
`

export const Content = styled.div`
    display: flex;
    flex: 1 1 auto;
    background-color: var(--sand);
`;