import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Home, NotFound, Account } from './pages';
import { Footer, Menu } from './components';
import { Main, Content } from './styled';

import { isPhone, isTablet } from "./helpers/responsive";

export const GeneralContext = React.createContext();

function App() {
  const [ hasPhone, setHasPhone ] = useState(isPhone(window.screen));
  const [ hasTablet, setHasTablet ] = useState(isTablet(window.screen));

  return (
    <GeneralContext.Provider value={{ 'hasPhone' : hasPhone, 'hasTablet': hasTablet }}>
      <Router>
        <Main>
          <Menu />
          <Content>
            <Routes>
              <Route exact path="/" element={<Home/>}/>
              {/* <Route path='/account' element={<Account/>}/> */}
              <Route path="*" element={<NotFound/>}/>
            </Routes>
          </Content>
          <Footer />
        </Main>
      </Router>
    </GeneralContext.Provider>
  );
}

export default App;
